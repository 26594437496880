var toggleChildren = function() {
  "use strict";

  // Select both footer-navigation__link with has-children and toggle-children
  var toggleLink = $('.footer-navigation__link.has-children');

  toggleLink.on('click', function(e) {
    e.preventDefault();

    var $link = $(this),
        siblingLink = $link.siblings('.toggle-children'), // Get the sibling with toggle-children class
        targetId = siblingLink.attr('href'); // Get the target dropdown ID from the arrow's href

    // Toggle showing-children class on the sibling toggle-children (the arrow)
    siblingLink.toggleClass('showing-children');

    // Toggle the dropdown (show-children) if targetId is valid
    if ($(targetId).length) {
      $(targetId).toggleClass('show-children');
    }

    return false;
  });
};

$(document).ready(function() {
  "use strict";
  toggleChildren();
});